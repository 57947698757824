<template>
	<footer>
		<div class="my-container">
			<div class="logo-background">
				<img src="/Images/footer background.png" alt="" />
			</div>
			<div class="top">
				<div class="title">Prime inks</div>
				<div
					class="description--footer"
					v-if="!loading"
					v-html="about.find((e) => e.Key === 'about')?.Value"
				></div>
				<div class="list">
					<div class="list-one">
						<div class="list-one-title">{{ $t("footer.categories") }}</div>
						<ul class="list-one-body">
							<li
								class="list-one-body-item"
								@click="
									hash.id = cate.name.split(' ')[0];
									$router.push({ name: 'home' });
								"
								v-for="cate in categories"
								:key="cate.id"
							>
								{{ cate.name }}
							</li>
							<!-- <li
								class="list-one-body-item"
								@click="
									hash.id = 'aqua';
									$router.push({ name: 'home' });
								"
							>
								{{ $t("categories.aqua-prime") }}
							</li>
							<li
								class="list-one-body-item"
								@click="
									hash.id = 'poly';
									$router.push({ name: 'home' });
								"
							>
								{{ $t("categories.poly-prime") }}
							</li>
							<li
								class="list-one-body-item"
								@click="
									hash.id = 'flexo';
									$router.push({ name: 'home' });
								"
							>
								{{ $t("categories.flexo-prime") }}
							</li>
							<li
								class="list-one-body-item"
								@click="
									hash.id = 'roto';
									$router.push({ name: 'home' });
								"
							>
								{{ $t("categories.roto-prime") }}
							</li> -->
						</ul>
					</div>
					<div class="list-one">
						<div class="list-one-title">{{ $t("footer.helpful-links") }}</div>
						<ul class="list-one-body">
							<li class="list-one-body-item">
								<router-link :to="{ name: 'home' }">
									{{ $t("footer.home") }}
								</router-link>
							</li>
							<li class="list-one-body-item">
								<router-link :to="{ name: 'all-news' }">
									{{ $t("footer.news") }}
								</router-link>
							</li>
							<li class="list-one-body-item">
								<router-link :to="{ name: 'all-albums' }">
									{{ $t("footer.albums") }}
								</router-link>
							</li>
							<li class="list-one-body-item">
								<router-link :to="{ name: 'contact-us' }">
									{{ $t("footer.contact-us") }}
								</router-link>
							</li>
							<li class="list-one-body-item">
								<router-link :to="{ name: 'about-us' }">
									{{ $t("footer.about") }}
								</router-link>
							</li>
						</ul>
					</div>
					<div class="list-one connect">
						<div class="list-one-title">{{ $t("footer.connect-us") }}</div>
						<ul class="list-one-body">
							<li class="list-one-body-item center-item">
								<i class="fab fa-facebook-f"></i>
							</li>
							<li class="list-one-body-item center-item">
								<i class="fab fa-twitter"></i>
							</li>
							<li class="list-one-body-item center-item">
								<i class="fab fa-instagram"></i>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="bottom">
				<div>Copy Rights &copy; All Rights Reserved 2022</div>
				<div><a href="https://beetronix.com"> BEETRONIX </a></div>
			</div>
		</div>
	</footer>
</template>

<script>
import { mapState } from "vuex";
import { hash } from "../variable";

export default {
	data() {
		return {
			loading: true,
			hash,
		};
	},
	computed: {
		...mapState({
			about: (state) => state.about.about,
			categories: (state) => state.categories.categories,
		}),
	},
	mounted() {
		this.$store.dispatch("about/fetchAll").then(() => {
			this.loading = false;
		});
	},
};
</script>

<style scoped lang="scss">
$padding-window-lg: 100px;
$padding-window-sm: 30px;

.my-container {
	position: relative;
	background: url("../../public/Shapes/footer.png");
	background-repeat: no-repeat;
	background-position: bottom left;
	padding-top: 100px;

	background-size: 95% 100%;
	@media (max-width: 1200px) {
		background-size: 100% 100%;
	}
	@media (max-width: 992px) {
		background-size: auto 100%;
		background-position: 22%;
	}

	padding-left: $padding-window-lg;
	@media (min-width: 1500px) {
		padding-left: 80px;
	}
	@media (max-width: 1200px) and (min-width: 992px) {
		padding-left: 50px;
	}
	@media (max-width: 576px) {
		padding-left: $padding-window-sm;
	}

	* {
		color: white;
	}
	.top {
		padding-right: 100px;
		max-width: 90%;
		@media (max-width: 992px) {
			padding-right: 150px;
		}
		@media (max-width: 576px) {
			max-width: 100%;
			padding-right: $padding-window-sm;
		}

		.title {
			font-size: 6rem;
			font-family: mainFontBold, mainFontBold-ar, sans-serif;
			text-align: left;
			@media (max-width: 768px) {
				font-size: 4.2rem;
			}
		}
		.description--footer {
			max-width: 450px;
			margin-bottom: 20px;
			@media (max-width: 992px) {
				text-align: justify;
			}
		}
		.list {
			display: flex;
			justify-content: space-between;
			@media (max-width: 992px) {
				flex-direction: column;
			}
			&-one {
				position: relative;
				z-index: 3;
				&-title {
					padding: 20px 0 10px;
					margin-bottom: 15px;
					font-size: 1.5rem;
					font-family: mainFontBold, mainFontBold-ar, sans-serif;
					text-transform: capitalize;
					position: relative;
					&::after {
						content: "";
						position: absolute;
						width: 70px;
						height: 1px;
						background: white;
						top: 100%;
					}
				}
				&-body {
					list-style: none;
					display: flex;
					flex-wrap: wrap;
					&-item {
						width: 50%;
						font-size: 1.4rem;
						text-transform: capitalize;
						padding: 6px 0;
						cursor: pointer;
						* {
							font-size: 1.4rem;
							text-transform: capitalize;
							padding: 6px 0;
						}
					}
				}
			}
			.connect {
				.list-one-body {
					flex-wrap: nowrap;
					&-item {
						background: #fff6;
						border-radius: 50%;
						width: 2.5rem;
						height: 2.5rem;
						margin-right: 1rem;
						font-size: 1.3rem;
					}
				}
			}
		}
	}
	.bottom {
		// position: absolute;
		display: flex;
		justify-content: space-between;
		max-width: 95%;
		padding: 10px 0;
		padding-right: 100px;
		@media (max-width: 576px) {
			padding-right: $padding-window-sm;
		}
		div:first-child {
			font-size: 0.65rem;
		}
		div:last-child {
			font-size: 0.95rem;
			font-family: mainFontBold, mainFontBold-ar, sans-serif;
		}
	}
}
.logo-background {
	position: absolute;
	bottom: 80px;
	left: $padding-window-lg;
	@media (min-width: 1500px) {
		left: 150px;
	}
	@media (max-width: 1200px) and (min-width: 992px) {
		left: 50px;
	}
	@media (max-width: 992px) {
		bottom: unset;
		top: 10%;
	}
	@media (max-width: 576px) {
		left: $padding-window-sm;
		right: $padding-window-sm;
	}
	img {
		width: 100%;
	}
}
</style>
<style>
.description--footer * {
	font-size: 1.3rem;
	line-height: 1.7rem;
	color: white;
	display: none;
}
.description--footer *:first-child {
	display: block;
}

[lang="en"] .list-one-title::after {
	left: 0;
}
[lang="ar"] .list-one-title::after {
	right: 0;
}
[lang="ar"] .my-container .top .list-one-body {
	flex-direction: row-reverse;
}
[lang="ar"] .description--footer {
	direction: rtl;
}
</style>