<template>
	<div class="container view-news">
		<loader v-if="loading" class="center-loader"></loader>
		<div class="contant" v-else>
			<div class="row">
				<div class="col-lg-8 col-md-8 col-12">
					<div class="main">
						<breadcrumbs
							class="mb-4"
							:links="[
								{ name: $t('breadcrumbs.home'), path: '/' },
								{ name: $t('breadcrumbs.news'), path: '/all-news' },
								{ name: oneNews.Title, path: '', active: true },
							]"
						></breadcrumbs>
						<div class="title mb-4 first-letter">
							{{ oneNews.Title }}
						</div>
						<div class="image mb-4">
							<img :src="oneNews.primary_image" alt="" />
						</div>
						<div class="description" v-html="oneNews.Content"></div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-12">
					<div class="see-more">
						<div class="title">{{ $t("news.see-more") }}</div>
						<loader class="center-loader" v-if="moreLoading"></loader>
						<div class="see-more__list" v-else>
							<div
								class="see-more__list__item"
								v-for="(n, i) in news.filter((e) => e.id != $route.params.id)"
								:key="i"
							>
								<router-link :to="{ name: 'view-news', params: { id: n.id } }">
									{{ n.Title }}
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { mapState } from "vuex";

export default {
	data() {
		return {
			loading: true,
			moreLoading: false,
			oneNews: {},
		};
	},
	components: {
		Loader,
		Breadcrumbs,
	},
	computed: {
		...mapState({
			news: (state) => state.news.news,
		}),
	},
	watch: {
		$route() {
			this.fetch();
		},
	},
	methods: {
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store
					.dispatch("news/fetchById", { id: this.$route.params.id })
					.then((res) => {
						this.oneNews = res;
					}),
				this.$store.dispatch("news/fetchAll"),
			]).then(() => {
				this.loading = false;
			});
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style scoped lang="scss">
.contant {
	margin-bottom: 5rem;
}
.main {
	padding-inline-end: 70px;
	@media (max-width: 768px) {
		// padding-inline-end: 0;
		padding: 0 20px;
	}
	.title {
		color: var(--main-color);
		font-size: 2.5rem;
		line-height: 2.5rem;
		font-family: mainFontBold, mainFontBold-ar, sans-serif;
	}
	.image {
		width: 100%;
		height: 40vw;
		border-radius: 3.5rem;
		border-top-left-radius: 0;
		overflow: hidden;
		@media (max-width: 768px) {
			height: 70vw;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.see-more {
	padding-top: 2rem;
	@media (max-width: 768px) {
		padding: 2rem 20px;
	}
	.title {
		color: var(--sec-color);
		font-size: 2rem;
		font-family: mainFontBold, mainFontBold-ar, sans-serif;
		text-transform: capitalize;
	}
	&__list {
		&__item {
			> a {
				font-size: 1.5rem;
			}
			padding: 1.5rem 0;
			&:not(:last-child) {
				border-bottom: 1px solid grey;
			}
		}
	}
}
</style>
<style lang="scss">
.view-news .description {
	* {
		font-size: 1.5rem;
		color: var(--font-color);
	}
}
[lang="en"] .first-letter::first-letter {
	text-transform: capitalize;
}
</style>