import * as types from "../mutations-types"

export default {
    namespaced: true,

    state: {
        slider: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/sliders').then((res) => {
                commit(types.STORE_SLIDER, res.data)
                return res.data
            })
        }
    },

    mutations: {
        [types.STORE_SLIDER](state, slider) {
            state.slider = slider
        }
    }
}