import { del } from 'vue';
import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        categories: []
    },

    actions: {
        fetchAll({ commit }) {
            return axios.get('/categories')
                .then((response) => {
                    commit(types.STORE_CATEGORIES, response.data);
                    return response.data;
                })
        },

        fetchById({ commit }, { id }) {
            return axios.get(`/categories/${id}`)
                .then((response) => {
                    return response.data;
                })
        },
    },

    mutations: {
        [types.STORE_CATEGORIES](state, categories) {
            state.categories = categories
        },
        del(state) {
            state.categories = []
        }
    },
}