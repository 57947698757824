<template>
	<div class="container">
		<loader v-if="loading"></loader>
		<div class="main row" v-else>
			<div
				class="item col-xl-3 col-lg-4 col-md-6 col-12 px-2 py-3"
				v-for="product in products"
				:key="product.id"
			>
				<product-card :product="product" :search="'search'"></product-card>
			</div>
		</div>
	</div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import Loader from "@/components/Loader.vue";
export default {
	components: { ProductCard, Loader },
	data() {
		return {
			products: [],
			loading: true,
		};
	},
	methods: {
		fetch() {
			this.loading = true;
			if (!this.$route.query.search) {
				this.$router.push({ name: "home" });
			}
			this.$store
				.dispatch("products/search", { text: this.$route.query.search })
				.then((res) => {
					this.products = res;
				})
				.then(() => {
					this.loading = false;
				});
		},
	},
	watch: {
		$route() {
			this.fetch();
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style scoped lang="scss">
.item {
	a {
		display: inline-block;
		width: 100%;
		height: 100%;
	}
}
</style>