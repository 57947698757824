<template>
	<div class="container">
		<loader class="center-loader" v-if="loading"></loader>
		<div class="contant" v-else>
			<vue-easy-lightbox
				escDisabled
				moveDisabled
				:visible="visible"
				:imgs="imgs"
				:index="index"
				@hide="handleHide"
			></vue-easy-lightbox>
			<breadcrumbs
				class="mb-4"
				:links="[
					{ name: $t('breadcrumbs.home'), path: '/' },
					{ name: $t('breadcrumbs.albums'), path: '/all-albums' },
					{ name: album.title, path: '/', active: true },
				]"
			></breadcrumbs>
			<div class="title mb-3 mt-4">{{ album.title }}</div>
			<div class="row">
				<div
					class="col-lg-3 col-md-4 col-sm-6 col-12 mb-3"
					v-for="(img, i) in album.images"
					:key="i"
				>
					<div
						class="album-image"
						@click="
							index = i;
							showMultiple();
						"
					>
						<img :src="img" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Loader from "@/components/Loader.vue";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
	components: {
		Loader,
		Breadcrumbs,
		VueEasyLightbox,
	},
	data() {
		return {
			loading: true,
			album: {},
			imgs: "",
			visible: false,
			index: 0,
		};
	},
	methods: {
		showMultiple() {
			this.imgs = this.album.images;
			this.show();
		},
		show() {
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
	},
	mounted() {
		this.$store
			.dispatch("albums/fetchById", { id: this.$route.params.id })
			.then((res) => {
				this.album = res;
			})
			.finally(() => {
				this.loading = false;
			});
	},
};
</script>

<style lang="scss" scoped>
.title {
	font-size: 3rem;
	font-family: mainFontBold, mainFontBold-ar, sans-serif;
	text-align: center;
}
.album-image {
	height: 300px;
	@media (max-width: 1200px) {
		height: 250px;
	}
	@media (max-width: 576px) {
		height: 95vw;
	}
	border-radius: 3rem;
	border-top-left-radius: 0;
	overflow: hidden;
	cursor: pointer;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
</style>