import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        history: []
    },

    actions: {
        fetchAll({ commit }) {
            return axios.get('/company-history')
                .then((response) => {
                    commit(types.STORE_PRODUCTS, response.data);
                    return response.data;
                })
        },

        fetchById({ commit }, { id }) {
            return axios.get(`/history/${id}`)
                .then((response) => {
                    return response.data;
                })
        },
    },

    mutations: {
        [types.STORE_PRODUCTS](state, history) {
            state.history = history
        }
    },
}