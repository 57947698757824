export const STORE_PRODUCTS = 'STORE_PRODUCTS';

export const STORE_CATEGORIES = 'STORE_CATEGORIES';

export const STORE_ABOUT = 'STORE_ABOUT';

export const STORE_HISTORY = 'STORE_HISTORY';

export const STORE_NEWS = 'STORE_NEWS';

export const STORE_ALBUMS = 'STORE_ALBUMS';

export const STORE_CONTACT = 'STORE_CONTACT';

export const STORE_SLIDER = 'STORE_SLIDER';