<template>
	<div class="container">
		<loader v-if="loading"></loader>
		<div class="main mb-5" v-else>
			<div class="row mb-5">
				<div class="col-md-6 col-12 pe-md-2 pe-0">
					<h3 class="about-title">{{ $t("about-us.mission") }}</h3>
					<div
						class="paragraph"
						v-html="about.find((e) => e.Key === 'mission')?.Value"
					></div>
				</div>
				<div class="col-md-6 col-12 ps-md-2 ps-0">
					<h3 class="about-title">{{ $t("about-us.vision") }}</h3>
					<div
						class="paragraph"
						v-html="about.find((e) => e.Key === 'vision')?.Value"
					></div>
				</div>
			</div>
			<div class="mb-5">
				<div class="about-title text-center">{{ $t("about-us.values") }}</div>

				<div
					class="
						about-us-logos
						d-flex
						align-items-center
						justify-content-center
					"
				>
					<div class="about-us-logos-logo">
						<img src="/Icons/1.png" alt="" />
						<span>{{ $t("value.reliable") }}</span>
					</div>
					<div class="about-us-logos-line"></div>
					<div class="about-us-logos-logo">
						<img src="/Icons/2.png" alt="" />
						<span>{{ $t("value.credible") }}</span>
					</div>
					<div class="about-us-logos-line"></div>
					<div class="about-us-logos-logo">
						<img src="/Icons/3.png" alt="" />
						<span>{{ $t("value.assurance") }}</span>
					</div>
				</div>
			</div>
			<div class="about-title text-center">{{ $t("about-us.about") }}</div>
			<div
				v-html="about.find((e) => e.Key === 'about')?.Value"
				class="paragraph"
			></div>
		</div>
	</div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { mapState } from "vuex";
export default {
	components: { Loader },
	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			about: (state) => state.about.about,
		}),
	},
	mounted() {
		this.$store.dispatch("about/fetchAll").then(() => {
			this.loading = false;
		});
	},
};
</script>

<style scoped lang="scss">
.main {
	max-width: 900px;
	margin: auto;
}
.title {
	text-align: center;
	font-size: 3.5rem;
	font-family: mainFontBold, mainFontBold-ar, sans-serif;
	max-width: 650px;
	margin: auto;
}
.about-us-logos {
	margin: auto;
	max-width: 650px;
	&-logo {
		height: 100px;
		@media (max-width: 992px) {
			height: 70px;
		}
		@media (max-width: 576px) {
			height: 50px;
		}
		display: flex;
		flex-direction: column;
		img {
			height: calc(100% - 2rem);
			object-fit: contain;
		}
		span {
			display: block;
			margin-top: 0.4rem;
			font-size: 1.6rem;
			line-height: 1.6rem;
			font-family: mainFontSemi, mainFontSemi-ar, sans-serif;
			text-transform: capitalize;
		}
	}
	&-line {
		flex: 1;
		height: 2px;
		margin: 0 10px 30px;
		@media (max-width: 576px) {
			height: 1px;
			margin: 0 5px 20px;
		}
		background: #ccc;
	}
}
.about-title {
	text-align: center;
	font-size: 2.4rem;
	font-family: mainFontBold, mainFontBold-ar, sans-serif;
	text-transform: capitalize;
	margin-bottom: 1rem;
}
</style>
<style lang="scss">
.paragraph {
	* {
		font-size: 1.5rem;
		text-align: center;
		color: var(--font-color-sub);
	}
}
</style>