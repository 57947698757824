import Vue from 'vue'
import Vuex from 'vuex'
import products from './modules/products'
import categories from './modules/categories'
import about from './modules/about'
import history from './modules/history'
import news from './modules/news'
import albums from './modules/albums'
import contact from './modules/contact'
import slider from './modules/slider'



Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		products,
		categories,
		about,
		history,
		news,
		albums,
		contact,
		slider
	}
})
