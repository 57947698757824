<template>
	<nav ref="nav">
		<img
			src="/Shapes/internal-nav.png"
			class="background background--pages"
			alt=""
		/>
		<img src="/Shapes/1.png" class="background background--home" alt="" />
		<img
			src="/Shapes/1-small.png"
			class="background background--home background--home--small"
			alt=""
		/>
		<div class="go-to-top center-item" @click="scrollToTop()">
			<i class="fa fa-angle-double-up"></i>
		</div>
		<div class="my-container">
			<div class="brand">
				<router-link :to="{ name: 'home' }">
					<img src="/Company Logo/logo.svg" alt="" />
				</router-link>
			</div>
			<div class="drawer d-lg-none d-block" @click="showDrawer = !showDrawer">
				<img src="/Shapes/mobile-nav-shape.png" alt="" />
				<i class="fa fa-bars"></i>
			</div>
			<div class="list" :class="{ show: showDrawer }">
				<div class="close-drawer" @click="showDrawer = false">
					<i class="fa fa-times"></i>
				</div>
				<ul>
					<router-link :to="{ name: 'home' }">
						<li>{{ $t("navbar.home") }}</li>
					</router-link>
					<li
						@click.self="showSubList = !showSubList"
						class="nav-item--categories"
					>
						{{ $t("navbar.categories") }}
						<i
							class="fa fa-angle-down"
							:class="showSubList ? 'fa-angle-up' : 'fa-angle-down'"
						></i>
						<div class="sub-list" :class="{ show: showSubList }">
							<ul>
								<li
									@click="
										hash.id = cate.name.split(' ')[0];
										$router.push({ name: 'home' });
										showSubList = null;
										showDrawer = null;
									"
									v-for="cate in categories"
									:key="cate.id"
								>
									{{ cate.name }}
								</li>
								<!-- <li
									@click="
										hash.id = 'poly';
										$router.push({ name: 'home' });
										showSubList = null;
										showDrawer = null;
									"
								>
									{{ $t("categories.poly-prime") }}
								</li>
								<li
									@click="
										hash.id = 'flexo';
										$router.push({ name: 'home' });
										showSubList = null;
										showDrawer = null;
									"
								>
									{{ $t("categories.flexo-prime") }}
								</li>
								<li
									@click="
										hash.id = 'roto';
										$router.push({ name: 'home' });
										showSubList = null;
										showDrawer = null;
									"
								>
									{{ $t("categories.roto-prime") }}
								</li> -->
							</ul>
						</div>
					</li>
					<router-link :to="{ name: 'all-news' }">
						<li>{{ $t("navbar.news") }}</li>
					</router-link>
					<router-link :to="{ name: 'all-albums' }">
						<li>{{ $t("navbar.albums") }}</li>
					</router-link>
					<router-link :to="{ name: 'about-us' }">
						<li>{{ $t("navbar.about") }}</li>
					</router-link>
					<router-link :to="{ name: 'contact-us' }">
						<li>{{ $t("navbar.contact-us") }}</li>
					</router-link>
					<li class="search">
						<i
							class="fa fa-search"
							:class="showSearch ? 'fa-times' : 'fa-search'"
							style="width: 27px"
							@click.self="showSearch = !showSearch"
						></i>
						<search-box
							class="search-box"
							:class="{ show: showSearch }"
						></search-box>
					</li>
					<li class="lang" @click="changeLang">{{ $t("lang") }}</li>
				</ul>
			</div>
		</div>
		<header class="header">
			<!-- <h1 class="title-main mb-lg-5 mb-4">
				{{ $t("navbar.header") }}
			</h1>
			<div class="header-line mb-lg-5 mb-4"></div>
			<h3
			v-else
			class="title-sub mb-lg-5 mb-4"
			v-html="mission.find((e) => e.Key === 'mission')?.Value"
			></h3> -->
			<loader v-if="loading"></loader>
			<div class="header--description" v-else ref="slider">
				<div
					class="header--description-item"
					v-for="(slide, i) in slider"
					:key="slide.id"
					v-html="slide?.description"
					:class="{ active: i === 0 }"
				></div>
			</div>
			<div
				class="mt-3"
				@click="hash.id = categories[0].name.split(' ')[0]"
				v-if="!loading"
			>
				<buttons class="header-btn" :text="$t('buttons.explore')"></buttons>
			</div>
		</header>
	</nav>
</template>

<script>
import Buttons from "./Buttons.vue";
import SearchBox from "./SearchBox.vue";
import { mapState } from "vuex";
import { hash, openCategories } from "../variable";
import { changeI18nLanguage } from "@/lang";
import Loader from "./Loader.vue";

export default {
	components: { SearchBox, Buttons, Loader },
	data() {
		return {
			showDrawer: false,
			showSubList: false,
			showSearch: false,
			hash,
			openCategories,

			loading: true,
		};
	},
	watch: {
		$route() {
			this.showDrawer = false;
			this.showSearch = false;
			if (this.$route.path === "/") this.$refs.nav.classList.add("home");
			else this.$refs.nav.classList.remove("home");
		},
		"openCategories.x"() {
			this.showDrawer = true;
			this.showSubList = true;
		},
	},
	computed: {
		...mapState({
			slider: (state) => state.slider.slider,
			categories: (state) => state.categories.categories,
		}),
	},
	methods: {
		scrollToTop() {
			window.scrollTo({
				top: 0,
			});
		},
		changeLang() {
			changeI18nLanguage();
		},
	},
	mounted() {
		if (this.$route.path === "/") this.$refs.nav.classList.add("home");
		else this.$refs.nav.classList.remove("home");
		Promise.all([
			this.$store.dispatch("slider/fetchAll"),
			this.$store.dispatch("categories/fetchAll"),
		]).then(() => {
			this.loading = false;
			var index = 0;
			setInterval(() => {
				index = ++index % this.slider.length;
				this.$refs.slider &&
					this.$refs.slider.childNodes.forEach((e) => {
						e.classList.remove("active");
					});
				this.$refs.slider &&
					this.$refs.slider.childNodes[index].classList.add("active");
			}, 2500);
		});
	},
};
</script>

<style scoped lang="scss">
$window-padding: 40px;
@media (max-width: 992px) {
	nav {
		margin-bottom: 30px;
	}
}
nav {
	overflow: hidden;
}
.header {
	height: 600px;
	@media (max-width: 1200px) {
		height: 500px;
	}
	max-height: 0;
	transition: 500ms;
	max-width: 480px;
	margin-left: 80px;
	margin-top: 50px;
	overflow: hidden;
	.title-main {
		font-size: 3.5rem;
		font-family: mainFontBold, mainFontBold-ar, sans-serif;
	}
	.header-line {
		width: 60px;
		height: 5px;
		background: var(--main-color);
		border-radius: 3px;
	}

	.header-btn {
		width: max-content;
		position: relative;
		z-index: 2;
	}
	@media (max-width: 1200px) {
		max-width: 400px;
		.title-main {
			font-size: 3rem;
		}
	}
	@media (max-width: 992px) {
		height: auto;
		max-width: unset;
		margin: 0 30px;
		width: 50%;
	}
}
.background {
	position: absolute;
	top: 0;
	right: 0;
	width: 75%;
	// height: 100%;
	max-height: 180px;
	transition: 1s opacity;
	@media (max-width: 576px) {
		width: 100%;
		&--home {
			right: -50px;
		}
	}
	&--home {
		opacity: 0;
	}
	&--pages {
		@media (max-width: 992px) {
			display: none;
		}
	}
}
.home {
	position: relative;
	.background {
		width: 80%;
		max-height: 750px;
		&--pages {
			opacity: 0;
		}
		&--home {
			opacity: 1;
			height: 100%;
			&--small {
				display: none;
			}
			@media (max-width: 992px) {
				& {
					display: none;
					&--small {
						display: block;
					}
				}
			}
		}
	}
	.header {
		max-height: 1000px;
	}
}
.my-container {
	display: flex;
	justify-content: space-between;
	position: relative;
	padding: 0 80px;
	@media (max-width: 1200px) {
		padding-right: 20px;
	}
	@media (max-width: 992px) {
		padding: 0 20px;
	}
}
.brand {
	flex: 1;
	margin: 30px 0 50px;
	text-align: left;
	max-width: 500px;
	@media (max-width: 992px) {
		max-width: 300px;
	}
	@media (max-width: 576px) {
		max-width: 200px;
	}
	img {
		width: 100%;
	}
}
.list {
	flex: 3;
	@media (max-width: 992px) {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 5;
		transition: 500ms;
		max-height: 0;
		overflow: hidden;
		width: 75%;
		&.show {
			max-height: 1000px;
		}
	}
	> ul {
		display: flex;
		justify-content: space-between;
		align-items: center;
		list-style: none;
		padding-left: 100px;
		@media (max-width: 1200px) {
			padding-left: 50px;
		}
		@media (max-width: 992px) {
			background: linear-gradient(-20deg, #a92768, #7a69a4);
			border-radius: 30px;
			border-top-right-radius: 0;
			padding: 50px 30px 30px;
			flex-direction: column;
			align-items: flex-start;
		}
		> a > li,
		> li {
			padding: 30px 5px 10px;
			font-size: 1.5rem;
			color: white;
			text-transform: capitalize;
			border-top: 7px solid transparent;
			position: relative;
			text-align: center;
			cursor: pointer;
			@media (max-width: 1400px) {
				font-size: 1.4rem;
			}
			@media (max-width: 992px) {
				padding: 10px 0px;
				text-align: left;
			}
			.sub-list {
				position: absolute;
				background: white;
				border-radius: 10px;
				box-shadow: 0 0 15px #0006;
				z-index: 2;
				transition: 300ms;
				max-height: 0;
				overflow: hidden;
				@media (max-width: 992px) {
					position: relative;
					background: transparent;
					box-shadow: none;
					* {
						color: white !important;
					}
				}
				> ul {
					display: flex;
					flex-direction: column;
					list-style: none;
					padding: 20px 25px;
					overflow: hidden;

					> li {
						color: var(--main-color);
						padding: 10px 0;
						font-size: 1.4rem;
						width: max-content;
					}
					@media (min-width: 992px) {
						> li:hover {
							color: var(--third-color);
							text-decoration: underline;
						}
					}
				}
			}
			.sub-list.show {
				max-height: 1000px;
			}
		}
		.contact {
			margin-top: 25px;
			padding: 5px 25px;
			border: 2px solid white;
			border-radius: 35px;
			border-top-right-radius: 0;
			transition: 200ms;
		}
		@media (min-width: 992px) {
			.contact:hover {
				background: white;
				color: var(--main-color);
			}
			a > li:not(.contact):not(.search):hover,
			> li:not(.contact):not(.search):hover {
				color: var(--third-color);
			}
		}
		.search {
			@media (max-width: 992px) {
				display: flex;
				align-items: center;
				flex-direction: row-reverse;
			}
			.search-box {
				position: absolute;
				top: 105%;
				width: 280px;
				max-height: 0;
				transition: 200ms ease-out;
				overflow: hidden;
				transform: translateY(-10px);
				&.show {
					max-height: 100px;
					transform: translateY(0);
					overflow: visible;
				}
				@media (max-width: 992px) {
					position: relative;
					max-height: unset;
					max-width: 0;
					width: 100%;
					transform: translateY(0);
					&.show {
						max-width: 1000px;
						margin-right: 10px;
					}
				}
			}
		}
	}
	.close-drawer {
		position: absolute;
		top: 30px;
		right: 70px;
		font-size: 2rem;
		color: white;
		@media (min-width: 992px) {
			display: none;
		}
		@media (max-width: 576px) {
			right: 40px;
		}
	}
}
.drawer {
	padding: 40px 70px;

	margin-right: -20px;
	color: white;
	font-size: 2rem;
	height: max-content;
	position: relative;
	img {
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		width: 120%;
	}
	@media (max-width: 576px) {
		padding: 30px 50px;
	}
}
.go-to-top {
	background: var(--third-color);
	font-size: 1.5rem;
	color: white;
	position: fixed;
	bottom: 3rem;
	right: 2rem;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	z-index: 10;
	opacity: 0.5;
	transform: 200ms;
	cursor: pointer;
	&:hover {
		opacity: 1;
	}
}
</style>
<style lang="scss">
[lang="ar"] {
	.list > ul {
		@media (min-width: 992px) {
			flex-direction: row-reverse;
		}
	}
	.nav-item--categories {
		direction: rtl;
	}
	.header-line {
		margin-left: auto;
	}
	.header-btn {
		margin-left: auto;
	}
	.go-to-top {
		right: unset;
		left: 2rem;
	}
}

.header {
	&--description {
		width: 100%;
		height: 300px;
		@media (max-width: 992px) {
			height: 250px;
		}
		@media (max-width: 576px) {
			height: 350px;
		}
		position: relative;
		overflow: hidden;
		&-item {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition-property: transform;
			transition-duration: 0;
			transition-delay: 200ms;
			background: var(--white-color);
			&.active {
				transform: translateX(0) !important;
				transition-property: transform;
				transition-duration: 200ms;
				transition-delay: 0ms;
				z-index: 1;
			}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size: 3.5rem;
				font-family: mainFontBold, mainFontBold-ar, sans-serif;
			}
			p {
				font-size: 1.4rem;
				color: var(--font-color-sub);
				position: relative;
				margin-top: 45px;
				line-height: 1.7rem;
				&::before {
					position: absolute;
					content: "";
					width: 60px;
					height: 5px;
					background: var(--main-color);
					border-radius: 3px;
					top: -25px;
				}
			}
		}
	}
}

[lang="en"] {
	.search-box {
		right: 0;
	}
	.header--description-item p::before {
		left: 0;
	}
	.header--description-item {
		transform: translateX(100%);
	}
}
[lang="ar"] {
	.search-box {
		left: 0;
	}
	.header--description-item p::before {
		right: 0;
	}
	.header--description-item {
		transform: translateX(-100%);
		direction: rtl;
	}
}
</style>