<template>
	<div class="container">
		<loader class="center-loader" v-if="loading"></loader>
		<div class="contant" v-else>
			<breadcrumbs
				class="mb-4"
				:links="[
					{ name: $t('breadcrumbs.home'), path: '/' },
					{ name: $t('breadcrumbs.news'), path: '/', active: true },
				]"
			></breadcrumbs>
			<div class="row">
				<div
					class="col-lg-4 col-md-6 col-12 mb-1"
					v-for="(n, i) in news"
					:key="i"
				>
					<div class="p-3 news-card">
						<news-card :news="n"></news-card>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import NewsCard from "@/components/NewsCard.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { mapState } from "vuex";
export default {
	data() {
		return {
			loading: true,
		};
	},
	components: {
		Loader,
		NewsCard,
		Breadcrumbs,
	},
	computed: {
		...mapState({
			news: (state) => state.news.news,
		}),
	},
	mounted() {
		this.$store
			.dispatch("news/fetchAll")
			.then()
			.finally(() => {
				this.loading = false;
			});
	},
};
</script>

<style scoped lang="scss">
.news-card {
	height: 500px;
	@media (max-width: 1200px) {
		height: 400px;
	}
	@media (max-width: 576px) {
		height: 100vw;
	}
}
</style>