import Home from "../views/Home.vue"
import ViewProduct from '../views/Product/ViewProduct.vue'
import ViewNews from '../views/News/ViewNews.vue'
import AllNews from '../views/News/AllNews.vue'
import AboutUs from '../views/AboutUs/AboutUs.vue'
import ContactUs from '../views/ContactUs/ContactUs.vue'
import AllAlbums from '../views/Albums/AllAlbums.vue'
import ViewAlbum from '../views/Albums/ViewAlbum.vue'
import Search from '../views/Product/Search.vue'

export default [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: AboutUs
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs
    },
    {
        path: '/products/:id',
        name: 'view-product',
        component: ViewProduct
    },
    {
        path: '/news/:id',
        name: 'view-news',
        component: ViewNews
    },
    {
        path: '/all-news',
        name: 'all-news',
        component: AllNews
    },
    {
        path: '/all-albums',
        name: 'all-albums',
        component: AllAlbums
    },
    {
        path: '/view-album/:id',
        name: 'view-album',
        component: ViewAlbum
    },
    {
        path: '/search',
        name: 'search',
        component: Search
    },
]