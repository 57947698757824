<template>
	<router-link :to="{ name: 'view-album', params: { id: album.id } }">
		<div class="my-card">
			<div class="image">
				<img :src="album.primary_image" alt="" />
			</div>
			<div class="text center-item text-center">
				<div class="text-description">{{ album.description }}</div>
			</div>
		</div>
		<div class="text-title text-center">{{ album.title }}</div>
	</router-link>
</template>

<script>
export default {
	props: ["album"],
};
</script>

<style scoped lang="scss">
.my-card {
	position: relative;
	border-radius: 2rem;
	border-top-left-radius: 0;
	overflow: hidden;
	cursor: pointer;
	&:hover {
		.text {
			top: 0;
		}
	}
}
.image {
	height: 280px;
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
.text-title {
	font-size: 2.2rem;
	font-family: mainFontSemi, mainFont-ar, sans-serif;
}
.text {
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	bottom: 0;
	background: #0009;
	padding: 2rem;
	transition: 300ms;
	overflow: hidden;
	&-description {
		font-size: 2rem;
		font-family: mainFontSemi, mainFont-ar, sans-serif;
		color: white;
	}
	@media only screen and (hover: none) {
		top: 0;
		background: #0004;
	}
}
</style>