<template>
	<Loader v-if="loading" class="center-loader-home"></Loader>
	<div id="app" v-else>
		<nav-bar></nav-bar>
		<router-view />
		<f-footer></f-footer>
	</div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FFooter from "./components/FFooter.vue";
import Loader from "./components/Loader.vue";
export default {
	components: { NavBar, FFooter, Loader },
	data() {
		return {
			loading: false,
		};
	},
	watch: {
		"$i18n.locale"() {
			window.axios.defaults.headers.common["accept-language"] =
				this.$i18n.locale;
			this.loading = true;
			this.$store.commit("categories/del");
			setTimeout(() => {
				this.loading = false;
			}, 1000);
		},
	},
};
</script>

<style lang="scss">
@media (screen) {
}
@font-face {
	font-family: mainFont;
	src: url("../public/Fonts/nordeco-font-family/NordecoCyrillic-Regular.ttf");
	unicode-range: U+00-7F;
}
@font-face {
	font-family: mainFont-ar;
	src: url("../public/Fonts/Cocon®\ Next\ Arabic-Light.otf");
	unicode-range: U+600 –6FF;
}
@font-face {
	font-family: mainFontBold;
	src: url("../public/Fonts/nordeco-font-family/Nordeco-Bold.ttf");
	unicode-range: U+00-7F;
}
@font-face {
	font-family: mainFontBold-ar;
	src: url("../public/Fonts/Nizar\ Cocon\ Kurdish\ Bold.otf");
	unicode-range: U+600 –6FF;
}
@font-face {
	font-family: mainFontSemi, mainFont-ar;
	src: url("../public/Fonts/nordeco-font-family/Nordeco-SemiBold.ttf");
}

:root {
	--main-color: #a61f77;
	--sec-color: #5298f2;
	--sec-color-sub: #50d4f2;
	--third-color: #f2b705;
	--third-color-sub: #f28705;

	--font-color: #222;
	--font-color-sub: #666;
	--white-color: #eff4fa;
}

* {
	box-sizing: border-box;
	padding: 0%;
	margin: 0%;
	font-family: mainFont, mainFont-ar, sans-serif;
	font-size: 16px;
	color: var(--font-color);
	@media (max-width: 1400px) {
		font-size: 14px;
	}
}
@media (max-width: 992px) {
	* {
		font-size: 11px;
	}
}

body {
	background: var(--white-color);
}

a {
	color: inherit;
	text-decoration: none;
}
a:hover,
a:focus {
	color: var(--third-color);
}

.container {
	margin-top: 40px;
	margin-bottom: 40px;
}

.center-item {
	display: flex;
	justify-content: center;
	align-items: center;
}
i {
	font-size: inherit;
	color: inherit;
}
ul {
	padding: 0;
}
.center-loader {
	align-items: center;
	margin: 150px auto;
}
.center-loader-home {
	height: 100vh;
	display: flex;
	align-items: center;
	margin: 0 auto !important;
	padding-bottom: 5rem;
}
.loader {
	margin: 5rem auto;
}
.title-sub {
	* {
		font-size: 1.4rem;
		color: var(--font-color-sub);
		padding: 0;
		margin-bottom: 0;
		line-height: 1.7rem;
	}
}
[lang="ar"] nav:not(.home) + div {
	direction: rtl;
}
</style>
