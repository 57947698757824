<template>
	<div class="loader">
		<div class="loader__main">
			<div class="loader__main__item"></div>
			<div class="loader__main__item"></div>
			<div class="loader__main__item"></div>
			<div class="loader__main__item"></div>
			<div class="loader__main__item"></div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.loader {
	width: max-content;
	direction: ltr;
	&__main {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100px;
		&__item {
			height: 10px;
			width: 10px;
			background: var(--main-color);
			border-radius: 50%;
			animation: smallMove 0.5s linear 0s infinite;
		}
		&__item:last-child {
			animation: bigMove 0.5s ease-in-out 0s infinite;
		}
	}
}

@keyframes smallMove {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(20px);
	}
}
@keyframes bigMove {
	0% {
		transform: translateX(0) translateY(0);
	}
	33% {
		transform: translateX(0) translateY(-30px);
	}
	66% {
		transform: translateX(-90px) translateY(-30px);
	}
	100% {
		transform: translateX(-90px) translateY(0);
	}
}
@keyframes color {
	0% {
		background: var(--main-color);
	}
	50% {
		background: var(--main-color);
	}
	51% {
		background: var(--third-color);
	}
	100% {
		background: var(--third-color);
	}
	// 50% {
	// 	background: var(--third-color);
	// }
	// 75% {
	// 	background: var(--sec-color-sub);
	// }
	// 100% {
	// 	background: var(--third-color-sub);
	// }
}
</style>