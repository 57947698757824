<template>
	<div class="container">
		<loader v-if="loading" class="center-loader"></loader>
		<div class="contant" v-else>
			<breadcrumbs
				:links="[
					{ name: $t('breadcrumbs.home'), path: '/' },
					{
						name: $t('breadcrumbs.categories'),
						path: '',
						button: true,
						allCategories: true,
					},
					{
						name: categorie?.name,
						path: '',
						button: true,
						categorie: categorie?.name.split(' ')[0],
					},
					{ name: product.name, path: '', active: true },
				]"
			></breadcrumbs>
			<div class="row">
				<div class="col-lg-7 col-12">
					<div class="main">
						<div class="title mt-3">{{ product.name }}</div>
						<div class="summary my-2" v-if="product.summary">
							{{ product.summary }}
						</div>
						<div class="article mb-5" v-html="product.description"></div>
					</div>
					<div class="contact d-lg-block d-none">
						<p @click="showForm = !showForm">
							{{ $t("product.request-sheet") }}
							<i
								class="fa"
								:class="
									showForm ? 'fa-angle-double-up' : 'fa-angle-double-down'
								"
							></i>
						</p>
						<div class="contact-container" :class="{ show: showForm }">
							<data-sheet-form></data-sheet-form>
						</div>
					</div>
				</div>
				<div class="col-lg-5 col-12">
					<div class="images">
						<div class="first">
							<img :src="product.images[0]" alt="" />
						</div>
						<div class="sec" v-if="product.images[1]">
							<img :src="product.images[1]" alt="" />
						</div>
					</div>
				</div>
				<div class="contact col-12 d-lg-none d-block">
					<p @click="showForm = !showForm">
						{{ $t("product.request-sheet") }}
						<i
							class="fa"
							:class="showForm ? 'fa-angle-double-up' : 'fa-angle-double-down'"
						></i>
					</p>
					<div class="contact-container" :class="{ show: showForm }">
						<data-sheet-form></data-sheet-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DataSheetForm from "@/components/DataSheetForm.vue";
import Loader from "@/components/Loader.vue";
import { mapState } from "vuex";
export default {
	components: {
		Breadcrumbs,
		DataSheetForm,
		Loader,
	},
	data() {
		return {
			showForm: false,
			product: {},
			loading: true,
		};
	},
	computed: {
		categorie() {
			return this.categories.find((e) => e.id === this.product?.sections_id[0]);
		},
		...mapState({
			categories: (state) => state.categories.categories,
		}),
	},
	methods: {
		fetch() {
			Promise.all([
				this.$store
					.dispatch("products/fetchById", {
						id: this.$route.params.id,
					})
					.then((res) => {
						this.product = res;
					}),
				this.$store.dispatch("categories/fetchAll"),
			]).then(() => {
				this.loading = false;
			});
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style scoped lang="scss">
.main {
	.title {
		font-size: 4rem;
		font-family: mainFontBold, mainFontBold-ar, sans-serif;
		text-transform: capitalize;
		color: var(--main-color);
		@media (max-width: 768px) {
			font-size: 3rem;
		}
	}
	.summary {
		font-size: 1.3rem;
	}
}
.contact {
	p {
		font-size: 2rem;
		line-height: 2.2rem;
		color: var(--third-color);
		font-family: mainFontBold, mainFontBold-ar, sans-serif;
		transition: 200ms;
		cursor: pointer;
		i {
			margin-inline-start: 1rem;
			font-size: 1.4rem;
			color: var(--third-color);
		}
	}
	p:hover {
		transform: scale(1.01);
	}
	&-container {
		padding: 0 15px;
		transition: 300ms;
		max-height: 0;
		overflow: hidden;
		&.show {
			max-height: 1000px;
		}
	}
}
.images {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	> div {
		width: 300px;
		height: 300px;
		overflow: hidden;
		border-radius: 30px;
		box-shadow: 0 0 20px #ccc;
		@media (max-width: 576px) {
			width: 45vw;
			height: 45vw;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	$margin: 15%;
	.first {
		margin-right: $margin;
		margin-bottom: 40px;
	}
	.sec {
		margin-left: $margin;
	}
	@media (max-width: 992px) {
		flex-direction: row;
		justify-content: space-around;
		.first,
		.sec {
			margin-right: 5px;
			margin-left: 5px;
		}
	}
}
</style>
<style>
.article * {
	font-size: 1.3rem;
}
.article ul {
	list-style-position: inside;
}
</style>