<template>
	<router-link :to="link" class="d-block">
		<div class="my-btn">
			<span>{{ text }}</span>
			<i class="fab fa-long-arrow-right"></i>
		</div>
	</router-link>
</template>

<script>
export default {
	props: { text: { default: "" }, link: { default: "" } },
};
</script>

<style scoped>
.my-btn {
	border-radius: 3rem;
	border-top-right-radius: 0;
	background: var(--third-color);
	padding: 0.6em 3em;
}
.my-btn span {
	color: white;
	font-size: 1.5em;
}
.my-btn i {
	color: white;
	font-size: 1.5em;
}
</style>