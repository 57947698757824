<template>
	<div class="container">
		<loader class="center-loader" v-if="loading"></loader>
		<div class="contant" v-else>
			<breadcrumbs
				class="mb-4"
				:links="[
					{ name: $t('breadcrumbs.home'), path: '/' },
					{ name: $t('breadcrumbs.albums'), path: '/', active: true },
				]"
			></breadcrumbs>
			<div class="row">
				<div
					class="col-lg-4 col-md-6 col-12 mb-4 news-card"
					v-for="(album, i) in albums"
					:key="i"
				>
					<div class="p-2">
						<album-card :album="album"></album-card>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import AlbumCard from "@/components/AlbumCard.vue";
import Loader from "@/components/Loader.vue";
import { mapState } from "vuex";

export default {
	components: {
		Breadcrumbs,
		AlbumCard,
		Loader,
	},
	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			albums: (state) => state.albums.albums,
		}),
	},
	mounted() {
		this.$store
			.dispatch("albums/fetchAll")
			.then()
			.finally(() => {
				this.loading = false;
			});
	},
};
</script>

<style scoped lang="scss">
</style>