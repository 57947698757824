import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import API_URL from './constants'
import i18n from './lang/index'

window.axios = axios.create({
  baseURL: API_URL,
});
window.axios.defaults.headers.common['accept-language'] = i18n.locale;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
