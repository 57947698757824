import { render, staticRenderFns } from "./Breadcrumbs.vue?vue&type=template&id=25b35b9e&scoped=true&"
import script from "./Breadcrumbs.vue?vue&type=script&lang=js&"
export * from "./Breadcrumbs.vue?vue&type=script&lang=js&"
import style0 from "./Breadcrumbs.vue?vue&type=style&index=0&id=25b35b9e&prod&scoped=true&lang=css&"
import style1 from "./Breadcrumbs.vue?vue&type=style&index=1&id=25b35b9e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25b35b9e",
  null
  
)

export default component.exports