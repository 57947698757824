<template>
	<div class="my-container">
		<div class="snackbar" v-if="sent">{{ $t("snackbar.sent-feedbck") }}</div>
		<form
			class="row justify-content-between"
			@submit.prevent="submit"
			novalidate
			ref="form"
		>
			<div class="input col-md-6 email" :class="{ 'not-valid': !validate }">
				<input
					type="email"
					class=""
					:placeholder="$t('components.data-sheet.email')"
					v-model="email"
					:disapled="loading"
					required
				/>
			</div>
			<div class="input col-md-6 name" :class="{ 'not-valid': !validate }">
				<input
					type="text"
					class=""
					:placeholder="$t('components.data-sheet.name')"
					v-model="name"
					:disapled="loading"
					required
				/>
			</div>
			<div
				class="input col-md-12 type custom-select"
				:class="{ 'not-valid': !validate }"
			>
				<select v-model="type" required :disapled="loading">
					<option selected disabled value="none">
						{{ $t("components.data-sheet.request-type") }}
					</option>
					<option value="quotation">
						{{ $t("components.data-sheet.type.quotation") }}
					</option>
					<option value="inquiring">
						{{ $t("components.data-sheet.type.inquiring") }}
					</option>
					<option value="suggestion">
						{{ $t("components.data-sheet.type.suggestion") }}
					</option>
					<option value="complain">
						{{ $t("components.data-sheet.type.complain") }}
					</option>
				</select>
			</div>
			<div class="input col-md-12 request" :class="{ 'not-valid': !validate }">
				<textarea
					class=""
					:placeholder="$t('components.data-sheet.request')"
					rows="4"
					v-model="body"
					:disapled="loading"
					required
				></textarea>
			</div>
			<div class="button" v-if="loading">
				<span></span>
			</div>
			<button class="button" type="submit" v-else>
				{{ $t("buttons.send") }}
			</button>
		</form>
	</div>
</template>

<script>
import Buttons from "./Buttons.vue";
export default {
	data() {
		return {
			name: null,
			email: null,
			type: "none",
			body: null,

			validate: true,
			loading: false,
			sent: false,
		};
	},
	components: {
		Buttons,
	},
	methods: {
		submit() {
			if (this.$refs.form.checkValidity()) {
				this.validate = true;
				this.loading = true;
				this.$store
					.dispatch("contact/feedback", {
						name: this.name,
						email: this.email,
						type: this.type,
						body: this.body,
					})
					.then(() => {
						this.sent = true;
						this.name = null;
						this.email = null;
						this.type = "none";
						this.body = null;
					})
					.catch((error) => {
						alert(error + "\nYour request was not sent , please try again.");
					})
					.finally(() => {
						this.loading = false;
					});
			} else {
				this.validate = false;
			}
		},
	},
	mounted() {},
};
</script>

<style scoped>
.input {
	border: 3px solid #ccc;

	border-radius: 2rem;
	border-top-right-radius: 0;
	overflow: hidden;
	margin: 10px 0;
}
.email {
	margin-inline-end: auto;
}
input,
select,
textarea {
	width: 100%;
	border: none;
	padding: 10px;
	font-size: 1.3rem;
	background: transparent !important;
	outline: none;
}
select * {
	background: white !important;
	padding: 10px;
	font-size: 1.3rem;
}
.button {
	background: none;
	outline: none;
	border: none;
	width: 150px;
	border-radius: 3rem;
	border-top-right-radius: 0;
	background: var(--third-color);
	padding: 0.6rem 3rem;
	color: white;
	font-size: 1.4em;
	text-align: center;
}
.button span {
	content: "";
	display: inline-block;
	margin: auto;
	font-size: 1.4rem;
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	border: 3px solid white;
	border-bottom: 3px solid transparent;
	animation: rotate 1s infinite;
}
@keyframes rotate {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}

@media (min-width: 768px) {
	.name,
	.email {
		width: 48%;
	}
}
.not-valid {
	border-color: red !important;
}
[disapled="true"] {
	color: grey;
}
.snackbar {
	font-size: 1.3rem;
	color: var(--font-color-sub);
	margin-top: 0.5rem;
}
/* style the select */
</style>