export default {
    namespaced: true,
    state: {},

    actions: {
        feedback({ commit }, { name, email, type, body }) {
            return window.axios
                .post('/feedback', { name, email, type, body })
                .then((response) => {
                    return response.data;
                });
        },
    },
};
