<template>
	<ul>
		<i class="fas fa-home svg" style="color: #999"></i>
		<li v-for="link in links" :key="link.name">
			<router-link
				:to="link.active ? {} : link.path"
				:class="{ active: link.active }"
				v-if="!link.button"
			>
				{{ link.name }}
			</router-link>
			<div
				class="d-inline-block"
				v-else
				@click="link.allCategories ? categories() : route(link.categorie)"
			>
				{{ link.name }}
			</div>
			<div class="arrow" v-if="!link.active"></div>
		</li>
	</ul>
</template>

<script>
import { hash, openCategories } from "@/variable";
export default {
	props: ["links"],
	methods: {
		categories() {
			openCategories.x++;
		},
		route(categorie) {
			hash.id = categorie;
			this.$router.push({ name: "home" });
		},
	},
};
</script>

<style scoped>
ul {
	list-style: none;
	padding: 0px !important;
	margin: 0;
}
.svg {
	padding-top: 4px;
	font-size: 1.3rem;
}
li {
	display: inline-block;
	padding: 0 5px;
}
li a,
li div {
	padding-right: 5px;
	text-decoration: none;
	font-size: 1.4rem;
	color: #999;
	text-transform: capitalize;
	cursor: pointer;
}
.arrow {
	display: inline-block;
	width: 6px;
	height: 6px;
	margin-bottom: 2px;
	border-right: 2px solid #999;
	border-bottom: 2px solid #999;
}
.active {
	color: var(--sec-color);
	text-transform: capitalize;
	font-family: mainFontBold, mainFontBold-ar, sans-serif;
}
@media (min-width: 1400px),
	(min-width: 1400px) and (min-resolution: 192dpi),
	(min-width: 1400px) and (-webkit-min-device-pixel-ratio: 2) {
	.arrow {
		width: 8px;
		height: 8px;
	}
}
</style>
<style>
[lang="en"] .arrow {
	transform: rotate(-45deg);
}
[lang="ar"] .arrow {
	transform: rotate(-225deg);
}
</style>
