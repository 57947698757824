<template>
	<div class="container">
		<div class="main row justify-content-between">
			<div class="col-md-7 col-12 mb-5">
				<div class="title mb-2">{{ $t("contact-us.request-sheet") }}</div>
				<data-sheet-form></data-sheet-form>
			</div>
			<div class="col-md-4 col-12">
				<div class="links">
					<div class="title mb-2">{{ $t("contact-us.connect-us") }}</div>
					<div class="links-item">
						<i class="fa fa-map-marker"></i>
						<span>
							{{ $t("contact-us.addres") }}
						</span>
					</div>
					<div class="links-item">
						<i class="fa fa-phone"></i>
						<span> +963321-4731063 </span>
					</div>
					<div class="links-item mb-3">
						<i class="fa fa-envelope"></i>
						<span> info@prime-inks.com </span>
					</div>
					<ul class="links-body">
						<li class="links-body-item center-item">
							<i class="fab fa-facebook-f"></i>
						</li>
						<li class="links-body-item center-item">
							<i class="fab fa-twitter"></i>
						</li>
						<li class="links-body-item center-item">
							<i class="fab fa-instagram"></i>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DataSheetForm from "@/components/DataSheetForm.vue";
export default {
	components: {
		DataSheetForm,
	},
};
</script>

<style scoped lang="scss">
.title {
	font-size: 2rem;
	font-family: mainFontBold, mainFontBold-ar, sans-serif;
	text-transform: capitalize;
}
.links {
	&-item {
		margin-bottom: 0.5rem;
		i {
			margin-inline-end: 0.5rem;
		}
		* {
			font-size: 1.3rem;
		}
	}
	&-body {
		display: flex;
		justify-content: space-between;
		width: 11rem;
		&-item {
			// border: 1px solid black;
			background: #ccc;
			border-radius: 50%;
			// padding: 1rem;
			width: 3.1rem;
			height: 3.1rem;
			font-size: 1.4rem;
		}
	}
}
</style>