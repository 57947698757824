<template>
	<div class="my-card">
		<router-link :to="{ name: 'view-news', params: { id: news.id } }">
			<div class="image">
				<img :src="news.primary_image" alt="" />
			</div>
		</router-link>
		<div class="name">
			{{ news.Title }}
		</div>
	</div>
</template>

<script>
export default {
	props: ["news"],
};
</script>

<style scoped lang="scss">
.my-card {
	height: 100%;
	max-width: 400px;
	margin: auto;
}
.image {
	height: calc(100% - 5.5rem);
	border-radius: 2.5rem;
	border-top-left-radius: 0;
	overflow: hidden;
	margin-bottom: 0.5rem;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: 600ms;
		&:hover {
			transition: 300ms;
			scale: 1.1;
		}
	}
}
.name {
	font-size: 2rem;
	line-height: 2rem;
	font-family: mainFontSemi, mainFont-ar, sans-serif;
}
</style>