<template>
	<loader v-if="loading" class="loader"></loader>
	<div class="" v-else>
		<section class="about-us text-center">
			<h1 class="text-capitalize">{{ $t("home.about-us") }}</h1>
			<p
				class="mb-4 title-sub about-us-paragraph"
				ref="read-more"
				v-html="
					about.find((e) => e.Key === 'about')?.Value +
					`<span class='read-more'>${$t('home.read-more')}</span>`
				"
			></p>
			<div
				class="about-us-logos d-flex align-items-center justify-content-center"
			>
				<div class="about-us-logos-logo">
					<img src="/Icons/1.png" alt="" />
					<span>{{ $t("value.reliable") }}</span>
				</div>
				<div class="about-us-logos-line"></div>
				<div class="about-us-logos-logo">
					<img src="/Icons/2.png" alt="" />
					<span>{{ $t("value.credible") }}</span>
				</div>
				<div class="about-us-logos-line"></div>
				<div class="about-us-logos-logo">
					<img src="/Icons/3.png" alt="" />
					<span>{{ $t("value.assurance") }}</span>
				</div>
			</div>
		</section>
		<section class="section aqua" :id="categories[0]?.name.split(' ')[0]">
			<div class="section-main">
				<div class="shape left">
					<img src="/Shapes/2.png" alt="" />
				</div>
				<div class="small-padding color-white xsmall-padding">
					<h1 class="section-main-title">
						{{ $t("categories.aqua-prime") }}
					</h1>
					<p class="section-main-summary">
						{{ categories[0].summary }}
					</p>
				</div>
				<div class="slider">
					<carousel
						:items="4"
						:nav="true"
						:dots="false"
						:navText="[
							`<i class='fa fa-angle-left'></i>`,
							`<i class='fa fa-angle-right'></i>`,
						]"
						:margin="40"
						:rewind="false"
						:autoWidth="true"
						class="blue"
					>
						<div class="small-margin"></div>
						<product-card
							v-for="product in productsArr[0]"
							:key="product.id"
							:product="product"
						></product-card>
					</carousel>
				</div>
			</div>
			<div class="benefits-padding">
				<div class="section-benefits">
					<div class="section-benefits-title">{{ $t("home.benefits") }}</div>
					<div
						class="section-benefits-html"
						v-html="categories[0].description"
					></div>
				</div>
			</div>
		</section>
		<section class="section flexo" :id="categories[1]?.name.split(' ')[0]">
			<div class="section-main">
				<div class="shape right">
					<img src="/Shapes/3.png" alt="" />
				</div>
				<div class="big-padding xsmall-padding">
					<h1 class="section-main-title">{{ $t("categories.flexo-prime") }}</h1>
					<p class="section-main-summary">
						{{ categories[1].summary }}
					</p>
				</div>
				<div class="slider">
					<carousel
						:items="4"
						:nav="true"
						:dots="false"
						:navText="[
							`<i class='fa fa-angle-left'></i>`,
							`<i class='fa fa-angle-right'></i>`,
						]"
						:margin="40"
						:rewind="false"
						:autoWidth="true"
					>
						<div class="big-margin"></div>
						<product-card
							v-for="product in productsArr[1]"
							:key="product.id"
							:product="product"
						></product-card>
					</carousel>
				</div>
			</div>
			<div class="benefits-padding">
				<div class="section-benefits">
					<div class="section-benefits-title">{{ $t("home.benefits") }}</div>
					<div
						class="section-benefits-html"
						v-html="categories[1].description"
					></div>
				</div>
			</div>
		</section>
		<section class="section poly" :id="categories[2]?.name.split(' ')[0]">
			<div class="section-main">
				<div class="shape left">
					<img src="/Shapes/4.png" alt="" />
				</div>
				<div class="small-padding color-white xsmall-padding">
					<h1 class="section-main-title">{{ $t("categories.poly-prime") }}</h1>
					<p class="section-main-summary">
						{{ categories[2].summary }}
					</p>
				</div>
				<div class="slider">
					<carousel
						:items="4"
						:nav="true"
						:dots="false"
						:navText="[
							`<i class='fa fa-angle-left'></i>`,
							`<i class='fa fa-angle-right'></i>`,
						]"
						:margin="40"
						:rewind="false"
						:autoWidth="true"
						class="yellow"
					>
						<div class="small-margin"></div>
						<product-card
							v-for="product in productsArr[2]"
							:key="product.id"
							:product="product"
						></product-card>
					</carousel>
				</div>
			</div>
			<div class="benefits-padding">
				<div class="section-benefits mb-lg-0 mb-4">
					<div class="section-benefits-title">{{ $t("home.benefits") }}</div>
					<div
						class="section-benefits-html"
						v-html="categories[2].description"
					></div>
				</div>
			</div>
		</section>
		<section class="section roto" :id="categories[3]?.name.split(' ')[0]">
			<div class="section-main">
				<div class="shape right">
					<img src="/Shapes/5.png" alt="" />
				</div>
				<div class="big-padding xsmall-padding roto-padding">
					<h1 class="section-main-title">{{ $t("categories.roto-prime") }}</h1>
					<p class="section-main-summary">
						{{ categories[3].summary }}
					</p>
				</div>
				<div class="slider">
					<carousel
						:items="4"
						:nav="true"
						:dots="false"
						:navText="[
							`<i class='fa fa-angle-left'></i>`,
							`<i class='fa fa-angle-right'></i>`,
						]"
						:margin="40"
						:rewind="false"
						:autoWidth="true"
					>
						<div class="big-margin"></div>
						<product-card
							v-for="product in productsArr[3]"
							:key="product.id"
							:product="product"
						></product-card>
					</carousel>
				</div>
			</div>
			<div class="benefits-padding">
				<div class="section-benefits">
					<div class="section-benefits-title">{{ $t("home.benefits") }}</div>
					<div
						class="section-benefits-html"
						v-html="categories[3].description"
					></div>
				</div>
			</div>
		</section>
		<div class="section quality">
			<div class="benefits-padding d-flex flex-lg-row flex-column">
				<div class="quality-images">
					<div class="three">
						<div
							class="image"
							v-for="(img, i) in qualityAlbum.images.slice(0, 3)"
							:key="i"
						>
							<div>
								<img :src="img" alt="" />
							</div>
						</div>
					</div>
					<div class="two">
						<div
							class="image"
							v-for="(img, i) in qualityAlbum.images.slice(3, 5)"
							:key="i"
						>
							<div>
								<img :src="img" alt="" />
							</div>
						</div>
					</div>
				</div>
				<div class="quality-text">
					<h1 class="quality-title">{{ $t("home.quality.title") }}</h1>
					<p class="quality-description">
						{{ $t("home.quality.description") }}
					</p>
					<div class="quality__table">
						<div class="quality__table__title">
							{{ $t(`home.quality.table.title`) }}
						</div>
						<div class="quality__table__item" v-for="i in 7" :key="i">
							<div class="quality__table__item__title">
								{{ $t(`home.quality.table.item-${i}.name`) }}
							</div>
							<div class="quality__table__item__value">
								{{ $t(`home.quality.table.item-${i}.value`) }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section slider">
			<div class="slider-title">
				{{ $t("home.over") }}
				<span class="slider-title--yellow">{{
					new Date().getFullYear() - 2002
				}}</span>
				{{ $t("home.years") }}
			</div>
			<div class="slider-sub-title mb-5">
				{{ $t("home.important-events") }}
			</div>
			<div class="slider-carousel">
				<carousel
					class="history-carousel"
					:responsive="{
						0: { items: 3 },
						992: { items: 5 },
					}"
					:dots="false"
					:navText="[
						`<i class='fa fa-angle-left'></i>`,
						`<i class='fa fa-angle-right'></i>`,
					]"
					ref="historyCarousel"
					@changed="historySlider"
					:loop="true"
					:autoplay="true"
					:autoplayHoverPause="true"
				>
					<div
						class="history-carousel-item"
						v-for="(h, i) in history"
						:key="i"
						:id="h.id"
					>
						{{ h.title.en }}
					</div>
				</carousel>
			</div>
			<p
				class="slider-paragraph"
				v-html="
					isEnglish
						? history.find((e) => e.id == historyIndex)?.description.en
						: history.find((e) => e.id == historyIndex)?.description.ar
				"
			></p>
		</div>
	</div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import Carousel from "vue-owl-carousel";
import Loader from "@/components/Loader.vue";
import { mapState } from "vuex";
import { hash } from "../variable";
import $ from "jquery";

export default {
	components: {
		Carousel,
		ProductCard,
		Loader,
	},
	data() {
		return {
			loading: true,
			productsArr: [[], [], [], []],
			historyIndex: 0,
			hash,

			qualityAlbum: [],
		};
	},
	watch: {
		"hash.id"() {
			this.scollFun(hash.id);
		},
	},
	methods: {
		scollFun(id) {
			if (!id) {
				return;
			}
			window.scrollTo({
				top: document.getElementById(id)?.offsetTop + 60,
			});
			hash.id = null;
		},
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch("products/fetchAll"),
				this.$store.dispatch("categories/fetchAll"),
				this.$store.dispatch("history/fetchAll"),
				this.$store.dispatch("about/fetchAll"),
				this.$store.dispatch("albums/fetchById", { id: 1 }).then((res) => {
					this.qualityAlbum = res;
				}),
			])
				.then(() => {})
				.finally(() => {
					this.sortProducts();
					setTimeout(() => {
						document
							.querySelector(".read-more")
							.addEventListener("click", () => {
								this.$refs["read-more"].classList.add("all");
							});
						this.scollFun(hash.id);
					}, 1);
				});
		},
		sortProducts() {
			this.products.forEach((e) => {
				for (let i = 0; i < 4; i++) {
					if (e.sections_id.includes(this.categories[i].id))
						this.productsArr[i].push(e);
				}
			});
			this.loading = false;
		},
		clickHistory(e) {
			var owl = $(".history-carousel > .owl-carousel");
			owl.owlCarousel();
			owl.trigger("next");
			// this.$attrs;
			// document
			// 	.querySelector(".owl-carousel")
			// 	.dispatchEvent(document.querySelector(".owl-carousel").to, 1);
		},
		historySlider(e) {
			e.currentTarget.querySelectorAll(".owl-item").forEach((e) => {
				e.addEventListener("click", this.clickHistory);
			});
			this.historyIndex = e.item.index % 6;
			setTimeout(() => {
				const arr = e.currentTarget.querySelectorAll(".active");
				document
					.querySelectorAll(".owl-item")
					.forEach((e) => e.classList.remove("yellow"));
				if (arr.length === 5) {
					arr[2].classList.add("yellow");
					this.historyIndex = arr[2].firstChild.id;
				} else {
					arr[1].classList.add("yellow");
					this.historyIndex = arr[1].firstChild.id;
				}
			}, 1);
		},
	},
	computed: {
		isEnglish() {
			return document.querySelector("html").lang === "en";
		},
		...mapState({
			products: (state) => state.products.products,
			categories: (state) => state.categories.categories,
			about: (state) => state.about.about,
			history: (state) => state.history.history,
		}),
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style scoped lang="scss">
.small-padding {
	padding: 0 80px;
}
.small-margin {
	width: 40px;
	@media (max-width: 992px) {
		width: 0;
	}
}
.big-padding {
	padding: 0 220px;
	@media (max-width: 1210px) {
		padding: 0 120px;
	}
}
.big-margin {
	width: 160px;
	@media (max-width: 1200px) {
		width: 80;
	}
	@media (max-width: 992px) {
		width: 0;
	}
}
@media (max-width: 992px) {
	.xsmall-padding {
		padding: 0 50px;
		* {
			color: white;
		}
	}
}
@media (min-width: 768px) and (max-width: 992px) {
	.roto-padding {
		padding-left: 120px;
	}
}
.benefits-padding {
	padding: 0 120px;
	@media (max-width: 992px) {
		padding: 0 50px;
	}
}
.about-us {
	max-width: 760px;
	padding: 0 30px;
	margin: auto;
	@media (max-width: 1200px) {
		margin-bottom: 120px;
	}
	h1 {
		font-family: mainFontBold, mainFontBold-ar, sans-serif;
		font-size: 3rem;
	}
	p {
		font-size: 1.5rem;
		line-height: 1.6rem;
		color: var(--font-color-sub);
	}
	&-logos {
		width: 90%;
		margin: auto;
		&-logo {
			height: 100px;
			@media (max-width: 992px) {
				height: 70px;
			}
			@media (max-width: 576px) {
				height: 50px;
			}
			display: flex;
			flex-direction: column;
			img {
				height: calc(100% - 2rem);
				object-fit: contain;
			}
			span {
				display: block;
				margin-top: 0.4rem;
				font-size: 1.6rem;
				line-height: 1.6rem;
				font-family: mainFontBold, mainFontBold-ar, sans-serif;
				text-transform: capitalize;
			}
		}
		&-line {
			flex: 1;
			height: 2px;
			margin: 0 10px 30px;
			@media (max-width: 576px) {
				height: 1px;
				margin: 0 5px 20px;
			}
			background: #ccc;
		}
	}
}
.section {
	padding: 80px 0;
	margin-bottom: 20px;
	@media (max-width: 992px) {
		padding-bottom: 60px;
	}
	&-main {
		position: relative;
		&-title {
			font-size: 3.5rem;
			font-family: mainFontBold, mainFontBold-ar, sans-serif;
			text-transform: capitalize;
			margin-bottom: 2rem;
			max-width: 400px;
		}
		&-summary {
			font-size: 1.5rem;
			line-height: 1.8rem;
			max-width: 400px;
			margin-bottom: 2rem;
			text-align: justify;
			@media (max-width: 768px) {
				margin-bottom: 5rem;
			}
		}
	}
	&-benefits {
		padding-top: 100px;
		max-width: 700px;
		margin-left: auto;
		@media (max-width: 992px) {
			margin-left: 0;
			padding-top: 30px;
		}
		&-title {
			font-size: 3rem;
			font-family: mainFontBold, mainFontBold-ar, sans-serif;
			text-transform: capitalize;
		}
	}
}
.shape {
	position: absolute;
	top: -100px;
	bottom: -100px;
	@media (max-width: 992px) {
		bottom: 0px;
	}
	@media (max-width: 768px) {
		bottom: 50px;
	}
	.aqua & {
		top: -200px;
		@media (max-width: 992px) {
			top: -150px;
			bottom: 50px;
		}
	}
	.roto & {
		top: -200px;
	}
	z-index: -1;
	width: 100%;
	img {
		display: block;
		height: 100%;
		@media (max-width: 992px) {
			width: 100% !important;
		}
	}
	&.left {
		left: 0;
		img {
			margin-right: auto;
		}
	}
	&.right {
		right: 0;
		img {
			margin-left: auto;
			width: 50%;
		}
	}
}
.quality {
	&-images {
		margin: auto;
		display: flex;
		@media (max-width: 992px) {
			flex-direction: column;
		}
		.three,
		.two {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: center;
			@media (max-width: 992px) {
				flex-direction: row;
			}
		}
		.two {
			margin-top: 100px;
			@media (max-width: 992px) {
				margin-top: 0;
				margin: auto;
			}
		}
		.image {
			width: 200px;
			height: 200px;
			@media (min-width: 1200px) {
				width: 250px;
				height: 250px;
			}
			padding: 20px;
			@media (max-width: 576px) {
				width: 50%;
				height: 40vw;
				padding: 10px;
			}
			> div {
				border-radius: 20px;
				overflow: hidden;
				width: 100%;
				height: 100%;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
	&-text {
		max-width: 700px;
		margin-left: auto;
		@media (max-width: 992px) {
			max-width: unset;
		}
	}
	&-title {
		font-size: 3.5rem;
		font-family: mainFontBold, mainFontBold-ar, sans-serif;
		text-transform: capitalize;
	}
	&-description {
		font-size: 1.5rem;
		line-height: 1.8rem;
	}
	&__table {
		display: flex;
		flex-wrap: wrap;
		&__title {
			font-size: 1.8rem;
			font-family: mainFontBold, mainFontBold-ar, sans-serif;
			text-transform: capitalize;
			color: var(--main-color);

			width: 100%;
			padding: 0 5px;
		}
		&__item {
			width: 50%;
			margin-bottom: 0.5rem;
			@media (max-width: 576px) {
				width: 100%;
			}
			&__title {
				font-size: 1.3rem;
				line-height: 1.5rem;
				font-family: mainFontBold, mainFontBold-ar, sans-serif;
			}
			&__value {
				font-size: 1.3rem;
			}
		}
	}
	> div {
		max-width: 1500px;
		margin: auto;
		@media (max-width: 576px) {
			padding: 0 20px;
		}
	}
}
.slider {
	&-title {
		text-align: center;
		font-size: 3.5rem;
		text-transform: capitalize;
		font-family: mainFontBold, mainFontBold-ar, sans-serif;
		line-height: 3.5rem;
		&--yellow {
			font-family: inherit;
			font-size: 5rem;
			color: var(--third-color);
		}
	}
	&-sub-title {
		text-align: center;
		font-size: 1.5rem;
		color: grey;
		line-height: 2rem;
	}
	&-carousel {
		padding: 0 10%;
	}
	&-paragraph {
		max-width: 40%;
		margin: auto;
		@media (max-width: 576px) {
			max-width: 80%;
		}
	}
}
.color-white * {
	color: white;
}
</style>
<style lang="scss">
.about-us-paragraph {
	max-height: 5.1rem;
	transition: 100ms;
	position: relative;
	overflow: hidden;
	.read-more {
		color: var(--main-color);
		position: absolute;
		bottom: 0;
		font-size: 1.2rem;
		cursor: pointer;
	}
	&.all {
		max-height: 30rem;
		.read-more {
			display: none;
		}
	}
}

.owl-carousel {
	// .owl-stage {
	// 	margin-left: 20px;
	// }
	.owl-nav {
		display: flex;
		position: absolute;
		top: -40%;
		right: 100px;
		@media (max-width: 768px) {
			top: -20%;
			right: 50px;
		}
		div.owl-prev,
		div.owl-next {
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 60px;
			height: 60px;
			font-size: 1.5rem;
			@media (max-width: 768px) {
				width: 40px;
				height: 40px;
			}
		}
	}
}
[lang="en"] .blue .owl-carousel {
	.owl-prev,
	.owl-next {
		background: var(--sec-color);
	}
}
.yellow .owl-carousel {
	.owl-prev,
	.owl-next {
		background: var(--third-color);
	}
}
.history-carousel {
	.owl-stage-outer {
		padding: 25px 0 20px;
	}
	&-item {
		font-size: 2.5rem;
		text-align: center;
		font-family: mainFontBold, mainFontBold-ar, sans-serif;
		color: grey;
		transition: 200ms;
		position: relative;
		> p {
			position: absolute;
			bottom: -50px;
			text-align: center;
		}
	}
	.owl-item.yellow * {
		color: black;
		transform: scale(2.3) translateY(-7px);
	}
	.owl-nav {
		display: flex !important;
		justify-content: space-between;
		right: -50px;
		left: -50px;
		top: 0;
		z-index: -1;
		* {
			background: transparent !important;
			color: black !important;
			font-size: 2rem !important;
		}
		@media (max-width: 576px) {
			right: -30px;
			left: -30px;
		}
	}
}
.slider-paragraph {
	height: 5.4rem;
	* {
		text-align: center;
		color: var(--font-color-sub);
		font-size: 1.8rem;
		line-height: 1.8rem;
	}
}

.section-benefits-html {
	* {
		font-size: 1.5rem;
		line-height: 1.7rem;
		color: var(--font-color-sub);
	}
	&-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		&-item {
			font-size: 1.5rem;
			margin-top: 2rem;
		}
	}
	table {
		border: none !important;
		tr {
			margin-bottom: 0.3rem;
		}
		td {
			padding-bottom: 0.5rem;
			padding-inline-start: 1.8rem;
			padding-inline-end: 1.8rem;
			position: relative;
			vertical-align: top;
		}
		td::before {
			content: "\2714";
			color: var(--main-color);
			position: absolute;
			top: 0;
		}
		@media (max-width: 576px) {
			td,
			tr {
				display: block !important;
				width: auto !important;
				height: auto !important;
			}
		}
	}
}
[lang="ar"] .section-benefits-html {
	td::before {
		right: 0;
	}
}
[lang="en"] .section-benefits-html {
	td::before {
		left: 0;
	}
}
[lang="en"] .read-more {
	right: 0;
	padding-left: 40px;
	background: linear-gradient(to right, transparent, var(--white-color) 30px);
}
[lang="ar"] .read-more {
	left: 0;
	padding-right: 40px;
	background: linear-gradient(to left, transparent, var(--white-color) 30px);
}

[lang="ar"] {
	.aqua {
		.shape {
			top: -110px;
			@media (max-width: 992px) {
				top: -190px;
				bottom: 90px;
			}
		}
		@media (min-width: 992px) {
			.section-main-title,
			.section-main-summary {
				margin-left: auto;
			}
			.color-white * {
				color: var(--font-color);
			}
			.owl-nav {
				right: unset;
				left: 150px;
				top: -35%;
			}
		}
	}
	.section-main-summary {
		direction: rtl;
	}
	.slider-sub-title {
		direction: rtl;
	}
}
</style>