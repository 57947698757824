<template>
	<div class="my-view">
		<div class="search-form">
			<form action="" @submit.prevent="search">
				<input
					type="text"
					:placeholder="$t('search') + ' ...'"
					v-model="searchText"
				/>
				<button type="submit"><i class="fa fa-search"></i></button>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			searchText: null,
		};
	},
	methods: {
		search() {
			this.$router.push({ name: "search", query: { search: this.searchText } });
		},
	},
};
</script>

<style scoped lang="scss">
.search-form {
	border: 2px solid #eee;
	border-radius: 40px;
	border-top-right-radius: 0;
	padding: 10px;
	background: white;
	box-shadow: 0 0 10px #444;
	width: 100%;
	form {
		display: flex;
		input {
			background: transparent;
			border: none;
			outline: none;
			font-size: 1.1rem;
			min-width: 0;
			width: calc(100% - 2rem);
		}
		button {
			background: transparent;
			border: none;
			color: #aaa;
			font-size: 1.2rem;
			margin-left: 5px;
		}
	}
}
</style>