import Vue from "vue";
import Vuei18n from "vue-i18n"
import en from './en.json'
import ar from './ar.json'

Vue.use(Vuei18n);
const i18n = new Vuei18n({
    locale: 'en',
    messages: {
        en: en,
        ar: ar
    }
})

export default i18n

export function changeI18nLanguage() {
    var html = document.querySelector('html')

    if (i18n.locale === 'ar') {
        i18n.locale = 'en'
        html.lang = 'en';
        html.style.textAlign = 'left';

        localStorage.setItem('prime-inks-language', JSON.stringify({ language: 'en' }))
    }
    else if (i18n.locale === 'en') {
        i18n.locale = 'ar'
        html.lang = 'ar';
        html.style.textAlign = 'right';

        localStorage.setItem('prime-inks-language', JSON.stringify({ language: 'ar' }))
    }
}

if ('prime-inks-language' in localStorage) {
    i18n.locale = JSON.parse(localStorage['prime-inks-language']).language;
    var html = document.querySelector('html')
    html.lang = i18n.locale
    html.style.textAlign = i18n.locale === 'ar' ? 'right' : 'left'
}