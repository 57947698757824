var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.loading)?_c('loader',{staticClass:"center-loader"}):_c('div',{staticClass:"contant"},[_c('breadcrumbs',{attrs:{"links":[
				{ name: _vm.$t('breadcrumbs.home'), path: '/' },
				{
					name: _vm.$t('breadcrumbs.categories'),
					path: '',
					button: true,
					allCategories: true,
				},
				{
					name: _vm.categorie?.name,
					path: '',
					button: true,
					categorie: _vm.categorie?.name.split(' ')[0],
				},
				{ name: _vm.product.name, path: '', active: true },
			]}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-7 col-12"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"title mt-3"},[_vm._v(_vm._s(_vm.product.name))]),(_vm.product.summary)?_c('div',{staticClass:"summary my-2"},[_vm._v(" "+_vm._s(_vm.product.summary)+" ")]):_vm._e(),_c('div',{staticClass:"article mb-5",domProps:{"innerHTML":_vm._s(_vm.product.description)}})]),_c('div',{staticClass:"contact d-lg-block d-none"},[_c('p',{on:{"click":function($event){_vm.showForm = !_vm.showForm}}},[_vm._v(" "+_vm._s(_vm.$t("product.request-sheet"))+" "),_c('i',{staticClass:"fa",class:_vm.showForm ? 'fa-angle-double-up' : 'fa-angle-double-down'})]),_c('div',{staticClass:"contact-container",class:{ show: _vm.showForm }},[_c('data-sheet-form')],1)])]),_c('div',{staticClass:"col-lg-5 col-12"},[_c('div',{staticClass:"images"},[_c('div',{staticClass:"first"},[_c('img',{attrs:{"src":_vm.product.images[0],"alt":""}})]),(_vm.product.images[1])?_c('div',{staticClass:"sec"},[_c('img',{attrs:{"src":_vm.product.images[1],"alt":""}})]):_vm._e()])]),_c('div',{staticClass:"contact col-12 d-lg-none d-block"},[_c('p',{on:{"click":function($event){_vm.showForm = !_vm.showForm}}},[_vm._v(" "+_vm._s(_vm.$t("product.request-sheet"))+" "),_c('i',{staticClass:"fa",class:_vm.showForm ? 'fa-angle-double-up' : 'fa-angle-double-down'})]),_c('div',{staticClass:"contact-container",class:{ show: _vm.showForm }},[_c('data-sheet-form')],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }