import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        products: []
    },

    actions: {
        fetchAll({ commit }) {
            return axios.get('/products')
                .then((response) => {
                    commit(types.STORE_PRODUCTS, response.data);
                    return response.data;
                })
        },

        fetchById({ commit }, { id }) {
            return axios.get(`/products/${id}`)
                .then((response) => {
                    return response.data;
                })
        },

        search({ commit }, { text }) {
            return axios.get('/products', { params: { search: text } })
                .then(response => {
                    return response.data
                })
        }
    },

    mutations: {
        [types.STORE_PRODUCTS](state, products) {
            state.products = products
        }
    },
}